/**
 * Returns the number of full years years between dates.
 *
 * @param dateFrom The start date.
 * @param dateTo The end date.
 */
export function getAge(dateFrom: number, dateTo = Date.now()): number {
  const epochYear = 1970;
  const monthDiff = dateTo - dateFrom;
  const monthDiffDate = new Date(monthDiff);
  const year = monthDiffDate.getUTCFullYear();
  return Math.abs(year - epochYear);
}
